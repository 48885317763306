<template>
  <div v-if="purchases">
    <v-btn v-if="showClose" class="info mt-4">
      Uždaryti
    </v-btn>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Ieškoti"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-col>
      <v-switch
        v-model="showOnlyMine"
        label="Rodyti tik mano"
        class="mt-2"
      ></v-switch>
    </v-col>
    <v-data-table
      light
      :search="search"
      :headers="headers"
      :items="activePurchases"
      :expanded.sync="expanded"
      no-data-text="Kolkas jokių užsakymų"
      show-expand
      must-sort
      sort-by="created_at"
      sort-desc="true"
      class="elevation-2"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding:2em">
          <div :key="value" v-for="value of getCartContent(item.cart_content)">
            <b>{{ value.title }}</b> x {{ value.qty }} ({{ value.price }}) eur
          </div>
          <div v-if="item.removed_items">
            <div style="color:red">Klientas pašalino:</div>
            <div :key="value" v-for="value of item.removed_items">
              <b>{{ value.title }}</b> x {{ value.qty }}
            </div>
          </div>
          <div v-if="item.qty_change_items">
            <div style="color:orange">Keitė šių prekių kiekius:</div>
            <div :key="value" v-for="value of item.qty_change_items">
              <b>{{ value.title }}</b>
            </div>
          </div>
        </td>
      </template>
      <template v-slot:item.shipping_code="{ item }">
        <v-chip v-if="item.status" color="#5F6766" dark>
          {{ getShippingStatuses(item.shipping_code) }}
          <span v-if="item.test"> (Testinis)</span>
        </v-chip>
        <v-select
          v-if="showStatusSelect == item.id"
          :items="getPurchaseStatuses"
          @change="changeStatus([$event, item.id])"
          item-text="text"
          item-value="val"
          label="Keisti statusą"
          solo
        ></v-select>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          @click="
            showStatusSelect = showStatusSelect == item.id ? null : item.id
          "
          v-if="item.status"
          :color="getColor(item.status)"
          dark
        >
          {{ getStatusTitle(item.status) }}
        </v-chip>
        <span v-if="item.test"> (Testinis)</span>
        <v-select
          v-if="showStatusSelect == item.id"
          :items="getPurchaseStatuses"
          @change="changeStatus([$event, item.id])"
          item-text="text"
          item-value="val"
          label="Keisti statusą"
          solo
        ></v-select>
      </template>
      <template v-slot:item.actions="{ item }">
        <div
          v-if="
            item.shipping_code != 'pats' && item.status == 'needs_to_be_shipped'
          "
        >
          <v-btn color="gray" tile @click="openLabel(item)">
            <v-icon left>
              mdi-printer
            </v-icon>
            Spausdinti etiketę
          </v-btn>
          <v-btn class="mt-4" color="#014C40" tile @click="markAsSent(item.id)">
            <v-icon left color="#fff">
              mdi-check-all
            </v-icon>
            <div style="color:#fff">Pažymėti, kad Išsiųstas</div>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    showOnlyMine: true,
    showStatusSelect: null,
    search: null,
    dialogDelete: false,
    expanded: [],
    singleExpand: false,
    headers: [
      {
        text: "Statusas",
        align: "start",
        value: "status",
      },
      {
        text: "Vardas",
        align: "start",
        value: "name",
      },
      {
        text: "El. paštas",
        align: "start",
        value: "email",
      },
      {
        text: "Telefonas",
        align: "start",
        value: "phone",
      },
      // {
      //   text: "Prekės",
      //   align: "start",
      //   value: "cart_content",
      // },
      {
        text: "Pirkimo ID",
        align: "start",
        value: "purchase_number",
      },
      {
        text: "Siuntimo būdas",
        align: "start",
        value: "shipping_code",
      },
      {
        text: "Prekių kaina",
        align: "start",
        value: "total_price",
      },
      {
        text: "Siuntimo kaina",
        align: "start",
        value: "shipping_price",
      },
      {
        text: "Data",
        align: "start",
        sortable: true,
        value: "created_at",
      },
      { text: "Siuntimo etiketė", value: "actions" },
    ],
    editedIndex: -1,
  }),

  computed: {
    ...mapState({
      user: (state) => state.user,
      operator: (state) => state.operator,
      purchases: (state) => state.purchases,
      callerID: (state) => state.callerID,
      currentCaller: (state) => state.currentCaller,
    }),

    activePurchases() {
      return this.purchases.filter((element) => {
        if (this.showOnlyMine) {
          return element.operator_id == this.operator.id;
        } else {
          return element;
        }
      });
    },

    getPurchaseStatuses() {
      return [
        {
          val: "shipping",
          text: "Siunčiamas",
        },
        {
          val: "needs_to_be_shipped",
          text: "Reikia išsiųsti",
        },
      ];
    },
  },

  mounted() {
    this.getPurchases();
  },

  watch: {},

  created() {},

  methods: {
    getShippingStatuses(status) {
      if (status == "dpd") {
        return "Siunčiama su DPD";
      } else if (status == "zity") {
        return "Siunčiama su ZitiCity";
      } else if (status == "pats") {
        return "Atsiims pats";
      }
    },
    getCartContent(cart) {
      console.log(JSON.parse(cart));
      const cartObj = JSON.parse(cart);
      let objToReturn = [];
      for (let itemKey in cartObj) {
        objToReturn.push({
          title: cartObj[itemKey].title,
          qty: cartObj[itemKey].qty,
          price: cartObj[itemKey].price,
        });
      }
      return objToReturn;
    },
    async changeStatus(event) {
      const result = await this.$root.axiosInstance.post(
        `updatePurchase/${event[1]}`,
        {
          data: {
            status: event[0],
          },
        }
      );
      this.$store.commit("updatePurchases", result.data.purchases);
    },
    getColor(status) {
      if (status == "needs_to_be_shipped") {
        return "#C33C22";
      } else if (status == "shipping") {
        return "#014C1A";
      } else if (status == "paid") {
        return "#014C1A";
      }
    },
    getStatusTitle(status) {
      if (status == "needs_to_be_shipped") {
        return "Reikia išsiųsti!";
      } else if (status == "shipping") {
        return "Išsiųstas";
      } else if (status == "paid") {
        return "Apmokėta";
      }
    },
    openLabel(item) {
      window.open(this.$root.backendUrl + item.parcel_label, "_blank");
    },
    async markAsSent(itemID) {
      const result = await this.$root.axiosInstance.post(
        `updatePurchase/${itemID}`,
        {
          data: {
            status: "shipping",
          },
        }
      );
      this.$store.commit("updatePurchases", result.data.purchases);
    },
    async getPurchases() {
      const result = await this.$root.axiosInstance.get(
        `getPurchasesForOperator/${this.operator.id}`
      );
      this.$store.commit("updatePurchases", result.data.purchases);
    },
  },
};
</script>

<style lang="scss">
.v-data-table__expand-icon {
  &:before {
    content: "Išskleisti";
  }
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  padding-bottom: 2em;
}
</style>
