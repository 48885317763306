var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.purchases)?_c('div',[(_vm.showClose)?_c('v-btn',{staticClass:"info mt-4"},[_vm._v(" Uždaryti ")]):_vm._e(),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ieškoti","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-switch',{staticClass:"mt-2",attrs:{"label":"Rodyti tik mano"},model:{value:(_vm.showOnlyMine),callback:function ($$v) {_vm.showOnlyMine=$$v},expression:"showOnlyMine"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"light":"","search":_vm.search,"headers":_vm.headers,"items":_vm.activePurchases,"expanded":_vm.expanded,"no-data-text":"Kolkas jokių užsakymų","show-expand":"","must-sort":"","sort-by":"created_at","sort-desc":"true"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"2em"},attrs:{"colspan":headers.length}},[_vm._l((_vm.getCartContent(item.cart_content)),function(value){return _c('div',{key:value},[_c('b',[_vm._v(_vm._s(value.title))]),_vm._v(" x "+_vm._s(value.qty)+" ("+_vm._s(value.price)+") eur ")])}),(item.removed_items)?_c('div',[_c('div',{staticStyle:{"color":"red"}},[_vm._v("Klientas pašalino:")]),_vm._l((item.removed_items),function(value){return _c('div',{key:value},[_c('b',[_vm._v(_vm._s(value.title))]),_vm._v(" x "+_vm._s(value.qty)+" ")])})],2):_vm._e(),(item.qty_change_items)?_c('div',[_c('div',{staticStyle:{"color":"orange"}},[_vm._v("Keitė šių prekių kiekius:")]),_vm._l((item.qty_change_items),function(value){return _c('div',{key:value},[_c('b',[_vm._v(_vm._s(value.title))])])})],2):_vm._e()],2)]}},{key:"item.shipping_code",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":"#5F6766","dark":""}},[_vm._v(" "+_vm._s(_vm.getShippingStatuses(item.shipping_code))+" "),(item.test)?_c('span',[_vm._v(" (Testinis)")]):_vm._e()]):_vm._e(),(_vm.showStatusSelect == item.id)?_c('v-select',{attrs:{"items":_vm.getPurchaseStatuses,"item-text":"text","item-value":"val","label":"Keisti statusą","solo":""},on:{"change":function($event){return _vm.changeStatus([$event, item.id])}}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""},on:{"click":function($event){_vm.showStatusSelect = _vm.showStatusSelect == item.id ? null : item.id}}},[_vm._v(" "+_vm._s(_vm.getStatusTitle(item.status))+" ")]):_vm._e(),(item.test)?_c('span',[_vm._v(" (Testinis)")]):_vm._e(),(_vm.showStatusSelect == item.id)?_c('v-select',{attrs:{"items":_vm.getPurchaseStatuses,"item-text":"text","item-value":"val","label":"Keisti statusą","solo":""},on:{"change":function($event){return _vm.changeStatus([$event, item.id])}}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(
          item.shipping_code != 'pats' && item.status == 'needs_to_be_shipped'
        )?_c('div',[_c('v-btn',{attrs:{"color":"gray","tile":""},on:{"click":function($event){return _vm.openLabel(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-printer ")]),_vm._v(" Spausdinti etiketę ")],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"#014C40","tile":""},on:{"click":function($event){return _vm.markAsSent(item.id)}}},[_c('v-icon',{attrs:{"left":"","color":"#fff"}},[_vm._v(" mdi-check-all ")]),_c('div',{staticStyle:{"color":"#fff"}},[_vm._v("Pažymėti, kad Išsiųstas")])],1)],1):_vm._e()]}}],null,false,1687035286)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }